export const enum Navigation {
    Login = '/login',
    Signup = '/signup',
    SalesProductList = '/sales/products',
    RentalProductList = '/rental/products',
    SalesProductDetail = '/sales/products/detail',
    RentalProductDetail = '/rental/products/detail',
    SignupSuccess = '/signup-success',
    SignupSelection = '/signup-selection',
    FastTrackRegistrationSuccess = '/fast-track-registration-success',
    NewRegistrationSuccess = '/new-registration-success',
    SalesCart = '/sales/cart',
    RentalCart = '/rental/cart',
    Landing = '/',
    SalesCheckout = '/sales/cart/checkout',
    RentalCheckout = '/rental/cart/checkout',
    SalesCheckoutSuccess = '/sales/cart/checkout/success',
    RentalCheckoutSuccess = '/rental/cart/checkout/success',
    Users = '/account/users',
    Account = '/account',
    AccountSettings = '/account/settings',
    Employees = '/account/employees',
    ForgotPassword = '/forgot-password',
    ResetPassword = '/reset-password',
    ActivateCustomer = '/activate-customer',
    ResetPasswordSuccess = '/reset-password-success',
    ResetPasswordRequestSuccess = '/reset-password-request-success',
    NotFound = '/not-found',
    OrderHistory = '/account/order-history',
    CtxOrderHistory = '/account/ctx-order-history',
    OfferHistory = '/account/offer-history',
    InvoiceHistory = '/account/invoice-history',
    Leads = '/account/leads',
    LeadDetails = '/account/leads/detail',
    Changelog = '/changelog',
    Downloads = '/account/downloads',
}
