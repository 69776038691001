<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { useMarketQuery } from '@/composables/market';
    import { computed } from 'vue';

    const { t } = useI18n();
    const { market } = useMarketQuery();

    const marketCode = computed(() => market?.value?.code.toLowerCase() ?? 'at');
    // TODO CPP-665: support i18n in the link
    const urlPrefix = computed(() => `https://www.containex.com/${marketCode.value}/de/`);
</script>

<template>
    <div class="footer-container">
        <footer class="footer">
            <!--
            CPP-653: deactivate for now
            <div class="container">
                <Menu :model="buyItems" class="footer-menu"></Menu>
                <Menu :model="buyItems" class="footer-menu"></Menu>
                <Menu :model="buyItems" class="footer-menu"></Menu>
                <Menu :model="buyItems" class="footer-menu"></Menu>
            </div>
            <Divider />
            -->

            <ul class="info-container container">
                <li class="info-container-item">
                    <span class="pi pi-info-circle"></span>
                    {{ t('NAVIGATION.FOOTER_INFO') }}
                </li>
                <li class="info-container-item">
                    <span class="pi pi-external-link"></span>
                    <a :href="urlPrefix + 'datenschutz'" class="link" target="_blank">{{
                        t('NAVIGATION.PRIVACY_POLICY')
                    }}</a>
                </li>
                <li class="info-container-item">
                    <span class="pi pi-external-link"></span>
                    <a :href="urlPrefix + 'nutzungsbedingungen'" class="link" target="_blank">{{
                        t('NAVIGATION.TERMS_OF_USE')
                    }}</a>
                </li>
                <li class="info-container-item">
                    <span class="pi pi-external-link"></span>
                    <a :href="urlPrefix + 'impressum'" class="link" target="_blank">{{ t('NAVIGATION.IMPRINT') }}</a>
                </li>
            </ul>
        </footer>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .footer-container {
        @include main.apply-scrollbar-spacing;

        position: sticky;
        top: 100vh;
        background-color: main.$color-background-bluegray;
    }

    .footer {
        max-width: 1200px;
        padding: main.$spacing-6;

        margin: 0 auto;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        > :deep(*) {
            flex: none;
            width: 100%;
            padding: main.$spacing-2;
        }
    }

    @include main.for-breakpoint-md {
        .container > :deep(*) {
            flex: none;
            width: auto;
            padding: 0;
        }
    }

    :deep(.footer-menu) {
        border: none;
        background-color: main.$color-background-bluegray;
    }

    :deep(.footer-menu .p-submenu-header) {
        background-color: main.$color-background-bluegray;
    }

    .info-container {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .link {
        color: main.$color-secondary-blue;
        text-decoration: none;
    }

    .link:hover {
        text-decoration: underline;
    }

    .info-container-item {
        display: flex;
        gap: main.$spacing-3;
        align-items: center;
    }
</style>
