import { computed, type ComputedRef } from 'vue';
import { useNavigationItemsStore } from '@/stores/navigation-items';
import { useI18n } from 'vue-i18n';
import { useMarketQuery } from '@/composables/market';
import { ProvisionType } from '@containex/portal-backend-dto';
import { Navigation } from '@/router/navigation';
import { NavArea } from '@/types/NavArea';
import type { MenuItem } from 'primevue/menuitem';
import { marketContainsProvision } from '@/util/marketContainsProvision';
import { useAuthenticationQuery } from './authentication';

export interface NavigationItemsQuery {
    activeArea: ComputedRef<NavArea>;
    navAreas: ComputedRef<Record<NavArea, TypedMenuItem>>;
}

interface TypedMenuItem extends MenuItem {
    navBarItems: {
        name: ProvisionType;
        label: string;
        route: Navigation;
    }[];
}

export interface NavigationItemsAction {
    setActiveArea(newValue: NavArea): void;
}

export function useNavigationItemsQuery(): NavigationItemsQuery {
    const store = useNavigationItemsStore();
    const { isUserLoggedIn } = useAuthenticationQuery();

    const { t } = useI18n();
    const { market } = useMarketQuery();

    return {
        activeArea: computed(() => store.activeArea),

        navAreas: computed(() => {
            const navMenuList: Record<number, TypedMenuItem> = {};

            if (market.value == null) {
                return navMenuList;
            }

            if (marketContainsProvision(market.value, ProvisionType.Sales)) {
                navMenuList[NavArea.BUY] = {
                    name: t('NAVIGATION.AREAS.BUY'),
                    icon: 'pi pi-shopping-cart',
                    description: t('NAVIGATION.AREAS.BUY_DESCRIPTION'),
                    navArea: NavArea.BUY,
                    navBarItems: [
                        {
                            name: ProvisionType.Sales,
                            label: t('NAVIGATION.MENU_ITEM.ALL_PRODUCTS'),
                            route: Navigation.SalesProductList,
                        },
                    ],
                };
            }

            if (marketContainsProvision(market.value, ProvisionType.Rental)) {
                navMenuList[NavArea.RENT] = {
                    name: t('NAVIGATION.AREAS.RENT'),
                    icon: 'pi pi-calendar',
                    description: t('NAVIGATION.AREAS.RENT_DESCRIPTION'),
                    navArea: NavArea.RENT,
                    navBarItems: [
                        {
                            name: ProvisionType.Rental,
                            label: t('NAVIGATION.MENU_ITEM.ALL_PRODUCTS'),
                            route: Navigation.RentalProductList,
                        },
                    ],
                };
            }

            navMenuList[NavArea.MY_AREA] = {
                name: t('NAVIGATION.AREAS.MY_AREA'),
                icon: 'pi pi-user',
                description: t('NAVIGATION.AREAS.MY_AREA_DESCRIPTION'),
                navArea: NavArea.MY_AREA,
                navBarItems: isUserLoggedIn.value
                    ? [
                          {
                              name: ProvisionType.Sales,
                              label: t('NAVIGATION.MENU_ITEM.OVERVIEW'),
                              route: Navigation.Account,
                          },
                      ]
                    : [],
            };
            return navMenuList;
        }),
    };
}

export function useNavigationItemsAction(): NavigationItemsAction {
    const store = useNavigationItemsStore();

    return {
        setActiveArea(newValue: NavArea): void {
            store.setActiveArea(newValue);
        },
    };
}
