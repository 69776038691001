import { type NavigationGuard } from 'vue-router';
import { useProvisionTypeAction } from '@/composables/provision-type';
import { ProvisionType } from '@containex/portal-backend-dto';

// eslint-disable-next-line func-style
export const inferProvisionType: NavigationGuard = (to) => {
    const provisionTypeAction = useProvisionTypeAction();

    const urlTargetName = to.name?.toString().toLowerCase();
    const targetNameIncludesSales = urlTargetName?.includes('/sales/') ?? false;
    const targetNameIncludesRental = urlTargetName?.includes('/rental/') ?? false;

    if (targetNameIncludesSales) {
        provisionTypeAction.setCurrentProvisionType(ProvisionType.Sales);
    } else if (targetNameIncludesRental) {
        provisionTypeAction.setCurrentProvisionType(ProvisionType.Rental);
    } else {
        provisionTypeAction.setCurrentProvisionType(undefined);
    }
};
