import { useAuthenticationStore } from '@/stores/authentication';
import { computed, type ComputedRef } from 'vue';
import { type CustomerDto, CustomerRole } from '@containex/portal-backend-dto';
import { LoginResult } from '@/model/login-result';
import useGlobalToast from '@/composables/useGlobalToast';
import { medusaCustomerToCustomerDto } from '@containex/portal-medusa-mapper';
import { type AxiosResponse, HttpStatusCode, isAxiosError } from 'axios';
// eslint-disable-next-line import-x/no-cycle
import { useCartAction } from './cart';
import { type Task, useAsyncTask } from 'vue-concurrency';
import { useMarketQuery } from './market';
import { useCompanyStore } from '@/stores/company';
import { customerApi, customerUpdatePasswordApi } from '@containex/portal-backend-api-client';
import { getLogger } from '@/logger/logger';
import { medusaClient } from '@/common/api/medusa-client';
import { httpClient } from '@/common/api/http-client';

export interface AuthenticationQuery {
    username: ComputedRef<string>;
    currentCustomer: ComputedRef<CustomerDto | undefined>;
    isUserLoggedIn: ComputedRef<boolean>;
    isAdmin: ComputedRef<boolean>;
}

export interface AuthenticationAction {
    fetchCurrentCustomerIfMissing: Task<void, []>;

    login(email: string, password: string): Promise<LoginResult>;

    logout(): Promise<void>;

    requestPasswordReset(email: string, language: string): Promise<void>;

    resetPassword(email: string, token: string, password: string): Promise<void>;

    activateCustomer(email: string, password: string, token: string, agbAccepted: boolean): Promise<void>;

    updatePassword(oldPassword: string, newPassword: string): Promise<AxiosResponse<void>>;

    deleteExpiredSession(): void;
}

export function useAuthenticationQuery(): AuthenticationQuery {
    const store = useAuthenticationStore();

    return {
        username: computed(() => store.username ?? ''),
        currentCustomer: computed(() => store.currentCustomer),
        isUserLoggedIn: computed(() => (store.userMail?.length ?? 0) > 0),
        isAdmin: computed(() => store.currentCustomer?.roles?.includes(CustomerRole.ADMIN) === true),
    };
}

export function useAuthenticationAction(): AuthenticationAction {
    const store = useAuthenticationStore();
    const cartAction = useCartAction();
    const logger = getLogger('AuthenticationAction');

    function deleteExpiredSession(): void {
        store.removeCurrentCustomer();
    }

    return {
        async login(email: string, password: string): Promise<LoginResult> {
            try {
                const body = { email, password };
                const response = await medusaClient.auth.authenticate(body, {
                    next: {
                        tags: ['auth'],
                    },
                });
                if (response.customer != null) {
                    store.setCurrentCustomer(medusaCustomerToCustomerDto(response.customer));
                    return LoginResult.Success;
                }
            } catch (error) {
                logger.error(error, 'login failed');
                const { errorToastOptions, addToast } = useGlobalToast();

                addToast({
                    ...errorToastOptions,
                    summary: 'LOGIN.ERROR_SUMMARY',
                    detail: 'LOGIN.ERROR_DETAIL',
                });
            }

            return LoginResult.Failure;
        },
        async logout() {
            await medusaClient.auth.deleteSession();

            store.removeCurrentCustomer();
            cartAction.resetAllCartStores();
            useCompanyStore().$reset();
        },
        fetchCurrentCustomerIfMissing: useAsyncTask(async (): Promise<void> => {
            if (store.currentCustomer != null) {
                return;
            }

            try {
                const response = await medusaClient.customers.retrieve();

                store.setCurrentCustomer(medusaCustomerToCustomerDto(response.customer));
            } catch (error: unknown) {
                if (isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
                    deleteExpiredSession();
                } else if (isAxiosError(error) && error.response?.status === HttpStatusCode.NotFound) {
                    deleteExpiredSession();
                } else {
                    logger.error(error, 'Fetch current customer failed');
                    throw error;
                }
            }
        }).drop(),
        async requestPasswordReset(email: string, language: string): Promise<void> {
            const market = useMarketQuery().market.value?.code ?? '';

            await customerApi.resetPassword(httpClient, {
                email,
                language,
                market,
            });
        },
        async resetPassword(email: string, token: string, password: string): Promise<void> {
            await medusaClient.customers.resetPassword({ email, token, password });
        },
        async activateCustomer(email: string, password: string, token: string, agbAccepted: boolean): Promise<void> {
            if (!agbAccepted) {
                return;
            }

            try {
                await customerApi.activateCustomer(httpClient, {
                    email,
                    password,
                    token,
                    agbAccepted,
                });
            } catch (error: unknown) {
                if (isAxiosError(error) && error.response?.status === HttpStatusCode.Forbidden) {
                    logger.error(error, 'Failed to activate customer, maybe agbs were not set or token expired');
                    throw error;
                } else {
                    logger.error(error, 'Failed to activate customer');
                    throw error;
                }
            }
        },
        async updatePassword(oldPassword: string, newPassword: string): Promise<AxiosResponse<void>> {
            return await customerUpdatePasswordApi.updatePassword(httpClient, {
                oldPassword,
                newPassword,
            });
        },
        deleteExpiredSession,
    };
}
